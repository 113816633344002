import React from 'react';
import honey from '../../image/karakyle.PNG';

// Since this component is simple and static, there's no parent container for it.
const AboutPage = () => {
  return (
    <div className="aboutPage">
      <div className="aboutPage_image" style={{backgroundImage:`url(${honey}`}}>

      </div>
    
      <div className="aboutPage_description">
      Blooming Bee is a family owned apiary dedicated to supplying fresh, natural honey while providing a safe and chemical free environment for our honey bees. Our hives are specially designed to fit the bee’s needs and maximize their health and sustainability. The range of services we offer prioritizes the education and engagement of our local communities. Our primary goal is to improve the health of our pollinators, the environment and those who invest in our mission. Are you ready to help us save the bees?
      </div>
    </div>
  );
};

export default AboutPage;
