import React from 'react';
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";

import AboutPage from "../AboutPage";
import HomePage from "../HomePage";
import NotFoundPage from "../NotFoundPage";
import HostAHive from '../HostAHive';
import Products from '../Products';
import Services from '../Services';
const TopNav = () => {

    

    return (
        <div className="grid">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/home" component={HomePage} />
            <Route path="/about-us" component={AboutPage} />
            <Route path="/host-a-hive" component={HostAHive}/>
            <Route path="/services" component={Services}/>
            <Route path="/products" component={Products}/>

            <Route component={NotFoundPage} />
          </Switch>
        </div>
    )
}
TopNav.propTypes = {
    children: PropTypes.element
  };
  
  export default TopNav;