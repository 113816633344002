export default {
  fuelSavings: {
    newMpg: '',
    tradeMpg: '',
    newPpg: '',
    tradePpg: '',
    milesDriven: '',
    milesDrivenTimeframe: 'week',
    displayResults: false,
    dateModified: null,
    necessaryDataIsProvidedToCalculateSavings: false,
    savings: {
      monthly: 0,
      annual: 0,
      threeYear: 0
    }
  },
  products:{
    1 : {
      product_id : 1
      ,product_name : 'Honey'
      ,product_description : 'Pure, raw, and unpasteurized wildflower honey harvested in Northwestern Georgia.'
      ,product_quanity : '12oz'
      ,product_price : 14.00
      ,product_image : "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.6cjK8rpqDgPsVotZN6StEgHaHJ%26pid%3DApi&f=1"
    },
    2 : {
      product_id : 1
      ,product_name : 'Bees Wax'
      ,product_description : 'Pure, filtered, and untreated. '
      ,product_quanity : '1lb'
      ,product_price : 10.00
      ,product_image : "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwaxingkara.com%2Fwp-content%2Fuploads%2F2015%2F12%2Fwk-beeswax.jpg&f=1&nofb=1"
    }
  }
};
