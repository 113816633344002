import React from 'react';

import honey from '../../image/xHoney.jpg';
import blueberries from '../../image/blueberries.jpg';
import xbegGarden from '../../image/xbegGarden.jpg';
import xbeeswarm from '../../image/xbeeswarm.jpg';
//import beeRemoval from '../../image/beeRemoval.jpg';

// Since this component is simple and static, there's no parent container for it.
const HostAHive = () => {
  return (
    <div className="services">

      <div className="serviceItem">
        <div className='serviceImage'style={{backgroundImage:`url(${honey}`}}></div>
        <div className="serviceTitle">
          Products
        </div>
        <div className='serviceDescription'>
          {`We provide all types of organic bee products such as honey, bee bread, bees wax. If there's something particular you are looking for please contact us.`}   
        </div>
      </div>

      <div className="serviceItem">
        <div className='serviceImage'style={{backgroundImage:`url(${blueberries})`}}></div>
        <div className="serviceTitle">
          Local Pollination
        </div>
        <div className='serviceDescription'>
          Help pollinate crops   
        </div>
      </div>

      <div className="serviceItem">
        <div className='serviceImage'style={{backgroundImage:`url(${xbegGarden})`}}></div>
        <div className="serviceTitle">
          Host A Hive
        </div>
        <div className='serviceDescription'>
        The health of our pollinators depends on you. If you would like to become more actively involved in our mission, consider hosting a hive on your property. Contact us for a free evaluation of your site. Get ready to enjoy the many benefits of having a pollination station and the satisfaction of making a difference!
        </div>
      </div>


      <div className="serviceItem">
        <div className='serviceImage'style={{backgroundImage:`url(${xbeeswarm})`}}></div>
        <div className="serviceTitle">
          Hive and swarm removal
          </div>
        <div className='serviceDescription'>
        If you locate a honey bee swarm or hive, contact us for a free evaluation. We will relocate them to a handcrafted and lovingly designed new home.
        </div>
      </div>



    </div>
  );
};

export default HostAHive;
