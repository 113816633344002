import React, {useState} from 'react';

import ImageSlideShow from '../sharedComponents/imageSlideShow';

//import img1 from '../../image/BloomingBeeLogo.jpg'
import img2 from '../../image/hive.jpg'
import img3 from '../../image/image1.jpg'
import img4 from '../../image/image2.jpg'
import img6 from '../../image/image4.jpg'
import img7 from '../../image/image5.jpg'
import img8 from '../../image/image6.jpg'
//import img9 from '../../image/test1.jpg'


const HomePage = () => {

const [arrayOfImages] = useState([
  img3,img4,img6,img7,img8,img2
]) 

  return (
    <div className='homePage'>
        <ImageSlideShow 
          arrayOfImages = {arrayOfImages}
        />
      
    </div>
  );
};

export default HomePage;
