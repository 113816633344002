import React from 'react';

import PropTypes from 'prop-types';

const productCard = ({
    id
    ,title
    ,discription
    ,size
    ,price
    ,image
}) => {
    const maxQuanity = [1,2,3,4,5];
//${window.location.origin}
    return(
        <div key={id} className='productCard'>
           
            <div className='image'
                style={
                    {backgroundImage:`url(${image})`}
                }
            ></div>
            <div className='line1'>
                <div className='title'>{title}</div>
                <div className='size'>{size}</div>
            </div>
            
            <div className='discription'>{discription}</div>
            <div className='line3'>
                <div className='price'>${price}</div>
                <div className='quanity'> Quanity
                    <select>
                        {maxQuanity.map(m => {
                            return <option key={m} value={m}>{m}</option>
                        })}
                    </select>
                </div>
            </div>
           
            <button className='addToCart'>Add to Cart</button>
        </div>
    )
}

productCard.propTypes = { 
    id : PropTypes.number.isRequired
    ,title : PropTypes.number
    ,discription : PropTypes.number
    ,size : PropTypes.number
    ,price : PropTypes.number
    ,image  : PropTypes.number
}

export default productCard;