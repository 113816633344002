
import initialState from './initialState';


export default function productsReducer(state = initialState.products, action) {

  switch (action.type) {
    default:
      return state;
  }
}
