import React, {useState} from 'react';
import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import {faArrowAltCircleLeft, faArrowAltCircleRight} from '@fortawesome/free-solid-svg-icons';

/*
Inputs
arrayOfImages - Array of images you want to display
*/
const ImageSlideShow = (
    {
        arrayOfImages
    }
    ) => {

        const [currentImage, set_currentImage] = useState(arrayOfImages[0])



  return (
    <div className='imageSlideShow'>
      <div className="imageSlideShowContainer">
        <div className='imageSlideShowContainerCurrentImage' 
            style={
                {backgroundImage:`url(${currentImage})`}
            }
        
        >

            {/**
            <div className='imageSlideShowContainerImageSideButtons'>
                <span 
                className='arrowRight' 
                >
                    <FontAwesomeIcon icon={faArrowAltCircleRight} size='3x' />
                </span>
                <span
                className='arrowLeft'  
                >
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} size='3x' />
                </span>
            </div>
             */}
        </div>    
        <div className='imageSlideShowContainerImageButtons'>
                {arrayOfImages.map(m => {
                    if(m == currentImage){
                        return <button 
                        value={m}
                        onClick={e => set_currentImage(e.target.value)} 
                        className='selectedImage'>

                        </button> 
                    } else {
                        return <button 
                        value={m}
                        onClick={e => set_currentImage(e.target.value)}>

                        </button> 
                    }
                })}
            </div>
      </div>
      
    </div>
  );
};

ImageSlideShow.propTypes = {
    arrayOfImages : PropTypes.array.isRequired
}
export default ImageSlideShow;
