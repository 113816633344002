import React from 'react';

// Since this component is simple and static, there's no parent container for it.
const HostAHive = () => {
  return (
    <div className="page">
      <h2 className=" alt-header">Host A Hive</h2>
      <p>
       Hosting a hive is one of the best and easiest ways to help the honey bees.

       Our host a hive program is a great way to enjoy having honey bees on your property and all the benifites that come with it. 
       We enjoy our honey bees but we are running out of room to put them.
       Host a hive program 
       
      </p>

      <p>How does it work?</p>

      <p>If you are interested please contact us at BLOOMINGBEE</p>

      <p>How much does it cost?</p>

      <p>Nothing :), We will provide all the equement and the honey bees. We will set everything up and conduct monthly inspections at no cost to you.</p>


      
    </div>
  );
};

export default HostAHive;
