import React from 'react';
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import logo from "../../../image/smallLogo.jpg"

const activeStyle = { color: 'blue' };
const TopNav = () => {

    

    return (
        <React.Fragment>
            <div className='logoNamePlaceHolder'>
                <div className='logoContainer'>
                    <div className="logoName" style={{backgroundImage:`url(${logo}`}}>
                        
                    </div>
                </div>
            </div>
            <div className="navbar">
                <div className="navbarContainer">
                    {/*<NavLink exact to="/" activeStyle={activeStyle}>Home</NavLink>*/}
                    <div className='navItem'><NavLink to="/home" activeStyle={activeStyle}>Home</NavLink></div>
                    
                    <div className='navItem'><NavLink to="/products" activeStyle={activeStyle}>Products</NavLink></div>

                    <div className='navItem'><NavLink to="/services" activeStyle={activeStyle}>Services</NavLink></div>

                {/**  <div className='navItem'><NavLink to="/host-a-hive" activeStyle={activeStyle}>Host A Hive</NavLink></div>*/}
                    
                    <div className='navItem'><NavLink to="/about-us" activeStyle={activeStyle}>About Us</NavLink></div>
                </div>
            </div>
        </React.Fragment>
    )
}
TopNav.propTypes = {
    children: PropTypes.element
  };
  
  export default TopNav;