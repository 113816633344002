import React, { useState } from 'react';
import PropTypes from "prop-types";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook, faYoutube}from '@fortawesome/free-brands-svg-icons'

const Footer = () => {

    const [facebookStyle] = useState({color : 'Dodgerblue'})
    const [youtubeStyle] = useState({color : 'red'})


    return (
        <React.Fragment>
            <div className="footerLinks">
               
                <a 
                href="https://www.facebook.com/TheBloomingBee"
                target="_blank"
                rel="noopener noreferrer"
                className='footerLinkItem facebookicon'>
                    <span style={facebookStyle}>
                        <FontAwesomeIcon icon={faFacebook} size='3x' />
                    </span>
                </a>
                <a 
                href='https://www.youtube.com/channel/UC3s6HsIxFtAnG9WBw4nxjKA'
                target="_blank"
                rel="noopener noreferrer"
                className='footerLinkItem'>
                    <span style={youtubeStyle}>
                        <FontAwesomeIcon icon={faYoutube} size='3x' />
                    </span>
                </a>
                
            </div>
        
            <div className='footerSubscribe'>
                <div className='footerSubscribeItems'>
                    <div className='footerSubscribeItemSideText'>
                        Updates and Discounts
                    </div>
                    <input
                    placeholder='EMAIL'
                    />
                    <button>Subscribe</button>
                </div>
            </div>
            
        </React.Fragment>
    )
}

Footer.propTypes = {
    children: PropTypes.element
  };
  
  export default Footer;