/* eslint-disable import/no-named-as-default */


import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";


import Footer from './global/Footer';
import TopNav from './global/TopNav';
import Routing from './global/Routing';
// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

const App = () => {
    
    return (
      <div className="root_grid">
        <TopNav/>
        <Routing/>
        <Footer/>
      </div>
    );
  }


App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
