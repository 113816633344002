import React from 'react';

import ProductCard from '../sharedComponents/productCards';

import { useSelector } from 'react-redux'
// Since this component is simple and static, there's no parent container for it.

const Products = () => {

  const listOfProducts = useSelector(state => Object.values(state.products))


  return (
    <div className="products">

      <div className="cardContainer">
        {listOfProducts.map(m => 
          <ProductCard key={m.product_id}
          id={m.product_id}
          title={m.product_name}
          discription={m.product_description}
          size={m.product_quanity}
          price={m.product_price}
          image={m.product_image}
          />
        )}
      </div>
    </div>
  );
};

export default Products;
